@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Praktika";
  src: url("assets/PraktikaRounded-MediumExt.ttf");
}
@font-face {
  font-family: "Praktika-Bold";
  src: url("assets/PraktikaRounded-BoldExt.ttf");
}
@font-face {
  font-family: "Praktika-ExtraBold";
  src: url("assets/PraktikaRounded-ExtraBoldExt.ttf");
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 5px;
  font-family: "Praktika", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("assets/texture.png");
}

.section-title {
  @apply underline underline-offset-[20px] text-center font-[Praktika-Bold] inline-block w-full text-[3rem] lg:text-[4rem] mb-20;
}

.section-subtitle {
  @apply underline underline-offset-[10px] text-center font-[Praktika-Bold] inline-block w-full text-[1.5rem] lg:text-[2rem] mb-10;
}

.btn {
  @apply shadow-lg border-2 border-black rounded-sm text-3xl cursor-pointer;
}

.nav-item {
  @apply px-14 py-6 cursor-pointer border-l-2 border-black h-full;
}
.contact {
  @apply btn w-96 h-28 text-center;
}

.btn:active {
  @apply shadow-none translate-x-[10px] translate-y-[10px] scale-95;
}

.guidelines {
  background-color: transparent;
  color: #000000;
  display: flex;
  flex-direction: column;
  gap: 2rem;


  .guidelines-section {
    h2 {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    ul {
      list-style-position: inside;

      li {
        margin: .2rem 0;
      }
    }
  }
}